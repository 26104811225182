export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"},{"name":"document-rating","content":"General"},{"name":"MobileOptimized","content":"320"},{"name":"revisit-after","content":"3 month"},{"name":"robots","content":"index, follow"},{"name":"X-UA-Compatible","content":"IE=edge,chrome=1"},{"hid":"description","name":"description","content":"What does Islam really teach? What are its fundamental beliefs, and what are the actions it enjoins? What is the Qur’an, and what wisdom does it contain? This website seek to answer these questions, and give the reader a perfect starting point to learn about this great faith."},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-TileColor","content":"#1a1a1a"},{"name":"msapplication-config","content":"/icons/browserconfig.xml"},{"name":"theme-color","content":"#1a1a1a"}],"link":[{"rel":"mask-icon","href":"/icons/safari-pinned-tab.svg","color":"#1a1a1a"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js","type":"text/javascript"}],"noscript":[],"title":"True Islam Site","htmlAttrs":{"lang":"en"},"bodyAttrs":{"class":["site"]}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"