import revive_payload_client_JeZARtXGoX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_12e45F2jfK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TwdgHfCwp2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nDCh8TvP3A from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uIsN0X2jMc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NR9adMNT5w from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_iewPXU64MY from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3GAR4MOEZm from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.24.0_typescript@5.4.5_vue@3.5.11_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/front/.nuxt/components.plugin.mjs";
import prefetch_client_oUa3voKPmV from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.12.12_encoding@0.1.13_eslint@8.56.0_ioredis@_roi4mfydtr2uylkwas5tyisl5a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/vercel/path0/apps/front/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/vercel/path0/apps/front/.nuxt/element-plus-injection.plugin.mjs";
import scrollspy_client_mRTZl1UJDi from "/vercel/path0/apps/front/src/plugins/scrollspy.client.js";
import firebase_client_lOvfsw6Ppv from "/vercel/path0/apps/front/src/plugins/firebase.client.ts";
import form_fhHqzO4Fku from "/vercel/path0/apps/front/src/plugins/form.js";
import scroll_to_directive_mvfaQkBu2F from "/vercel/path0/apps/front/src/plugins/scroll-to-directive.js";
import scroll_to_helper_rsMANC87cn from "/vercel/path0/apps/front/src/plugins/scroll-to-helper.js";
import toggle_collapse_directive_cygon8PgEz from "/vercel/path0/apps/front/src/plugins/toggle-collapse-directive.js";
import vee_validate_client_45gWhfAHXv from "/vercel/path0/apps/front/src/plugins/vee-validate.client.js";
import vue_click_away_QoBmAA3ck9 from "/vercel/path0/apps/front/src/plugins/vue-click-away.js";
import vue_gtm_client_3YPaNVLi9H from "/vercel/path0/apps/front/src/plugins/vue-gtm.client.js";
import vue_social_sharing_glaVIJc3fZ from "/vercel/path0/apps/front/src/plugins/vue-social-sharing.js";
import wp_api_provider_plugin_GTJSRz7MzG from "/vercel/path0/apps/front/src/plugins/wp-api-provider.plugin.js";
export default [
  revive_payload_client_JeZARtXGoX,
  unhead_12e45F2jfK,
  router_TwdgHfCwp2,
  payload_client_nDCh8TvP3A,
  navigation_repaint_client_uIsN0X2jMc,
  check_outdated_build_client_NR9adMNT5w,
  chunk_reload_client_iewPXU64MY,
  plugin_vue3_3GAR4MOEZm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oUa3voKPmV,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  scrollspy_client_mRTZl1UJDi,
  firebase_client_lOvfsw6Ppv,
  form_fhHqzO4Fku,
  scroll_to_directive_mvfaQkBu2F,
  scroll_to_helper_rsMANC87cn,
  toggle_collapse_directive_cygon8PgEz,
  vee_validate_client_45gWhfAHXv,
  vue_click_away_QoBmAA3ck9,
  vue_gtm_client_3YPaNVLi9H,
  vue_social_sharing_glaVIJc3fZ,
  wp_api_provider_plugin_GTJSRz7MzG
]